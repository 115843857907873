@charset "UTF-8";
/* ========================================================
変数
======================================================== */
/* ========================================================
変数
======================================================== */
/* ========================================================
	next.css => 次回募集に関してページ用CSS
======================================================== */
.next-section {
  padding-top: 0;
}