@charset "utf-8";

/* ========================================================
変数
======================================================== */
$font_color: #101010;
$main_color: #23395B;



$sub_color: #333333;
$sub_color2: #FCF8ED;
$h_item1: #55C0F1;
$h_item2: #A0C500;
$h_item3: #689731;
$h_item4: #750013;
$h_item5: #EA645B;
$h_item6: #987DBA;

$color_base: #eee;
$color_main: #000;
$color_sub: #000;
$color_accent: #000;
$color_link: #04c;
$width_sp: 95%;
$width_pc: 960px;
$hover_opacity: 0.7;
$anime_nomal: all 0.2s ease-in-out;