@charset "utf-8";

@import "../color.scss";
@import "../mixin.scss";

/* ========================================================
変数
======================================================== */

$img_path: '../../images';
$c_img_path: '../../images/pages/next';

/* ========================================================
	next.css => 次回募集に関してページ用CSS
======================================================== */

.next{
	&-section{
		padding-top: 0;
	}
}